import $ from "jquery";

$(() => {
  const copyBlocks = $(".copy-block");
  if (copyBlocks.length) {
    copyBlocks.each((i, copyBlockEl) => {
      const copyBlock = $(copyBlockEl);
      if (copyBlock.find(".truncate-button").length) {
        const readButton = copyBlock.find(".truncate-button");

        readButton.on("click", function () {
          copyBlock.find(".wysiwyg").toggleClass("line-clamp-[8]");
          console.log(readButton);
          if (readButton[0].innerText === "Read Less") {
            readButton[0].innerText = "Read More";
          } else {
            readButton[0].innerText = "Read Less";
          }
        });
      }
    });
  }
});
