import $ from "jquery";

$(() => {
  // const heroHeadlines = $(".headline-effect");
  // function positionHeroSpan() {
  //   if (heroHeadlines.length) {
  //     heroHeadlines.each((i, heroHeadlineEl) => {
  //       const heroHeadline = $(heroHeadlineEl);
  //       const firstSpan = heroHeadline.children('span:first-of-type');
  //       const lastSpan = heroHeadline.children('span:last-of-type');
  //       if( lastSpan.length ) {
  //         const top = firstSpan.offset().top;
  //         lastSpan.css('top', top);
  //         lastSpan.css('position', 'fixed');
  //       }
  //     });
  //   }
  // };
  // function fadeHeroSpan() {
  //   if (heroHeadlines.length) {
  //     const fade = Math.max($(window).scrollTop() / 500, 0);
  //     // console.log('fade: ', fade);
  //     heroHeadlines.each((i, heroHeadlineEl) => {
  //       const heroHeadline = $(heroHeadlineEl);
  //       const lastSpan = heroHeadline.children("span:last-of-type");
  //       if (lastSpan.length) {
  //         lastSpan.css("opacity", fade);
  //       }
  //     });
  //   }
  // }
  // // positionHeroSpan();
  // fadeHeroSpan();
  // // window.onresize = () => { positionHeroSpan() };
  // window.onscroll = () => {
  //   fadeHeroSpan();
  // };

  const notificationBanners = $(".hero-notification-banner");
  if (notificationBanners.length) {
    notificationBanners.each((i, notificationBannerEl) => {
      const notificationBanner = $(notificationBannerEl);
      const closeButton = notificationBanner.find(".notification-close");

      closeButton.on("click", () => {
        console.log("close");
        notificationBanner.addClass("-translate-y-full");
      });
    });
  }

  const heroBanners = $(".hero");
  if (heroBanners.length) {
    heroBanners.each((i, heroBannerEl) => {
      const heroBanner = $(heroBannerEl);
      if (heroBanner.find("video")) {
        const heroVideo = heroBanner.find("video")[0];
        const pauseButton = heroBanner.find(".pause-video");
        console.log("heroVideo: ", heroVideo);
        pauseButton.on("click", () => {
          if (heroVideo.paused) {
            heroVideo.play();
          } else {
            heroVideo.pause();
          }
          pauseButton.find("div").toggleClass("hidden");
        });
      }
    });
  }
});
