import $ from "jquery";
import Muuri from "muuri/dist/muuri";

$(() => {
  const filterStatus = $("#filter-status");
  const filters = $(".program-filter");
  if (filters.length) {
    filters.each((i, filterEl) => {
      const filter = $(filterEl);
      let terms = [];
      let filterClear = filter.find("#clear-filters");

      // var grid = new Muuri(".muuri-program-grid", { dragEnabled: true });
      var grid = new Muuri(".muuri-program-grid");

      if (window.location.hash) {
        let hash = "." + window.location.hash.substring(1);
        // console.log("hash: ", hash);
        // grid.filter(hash);
      }

      const checkIfEmpty = () => {
        const visible = $(".muuri-program-grid > div:not(.muuri-item-hidden)");
        const emptyNotice = filter.siblings(".empty-grid");
        console.log(emptyNotice);
        console.log("visible: ", visible.length);
        if (visible.length === 0) {
          emptyNotice.removeClass("hidden");
        } else {
          emptyNotice.addClass("hidden");
        }
      };

      const filterPrograms = () => {
        // console.log("filtering");
        let gridFilters = "";
        terms.map((term) => {
          if (term.slug) {
            gridFilters += "." + term.filter + "-" + term.slug;
          }
        });
        console.log("filters: ", gridFilters);
        if (gridFilters) {
          // grid.filter(gridFilters, { onFinish: checkIfEmpty() });
          grid.filter(gridFilters);
          filterClear.removeClass("hidden");
          // const visible = $(
          //   ".muuri-program-grid > div:not(.muuri-item-hidden)"
          // );
          // console.log("visible: ", visible.length);
        } else {
          grid.filter(".item");
          filterClear.addClass("hidden");
        }
        setTimeout(() => {
          checkIfEmpty();
        }, 500);
        // console.log("terms: ", terms);
        console.log("filterStatus: ", filterStatus);
        filterStatus[0].textContent = "Filtered program results available";
      };

      filterClear.on("click", () => {
        terms = [];
        grid.filter(".item");
        filterClear.addClass("hidden");
        const fields = filter.find(".archive-filter");
        if (fields.length) {
          fields.each((i, fieldEl) => {
            const field = $(fieldEl).find("p")[0];
            const title = field.id
              .replace("filter-name-", "")
              .replace("_", " ");
            // console.log("title: ", title);
            field.innerHTML = title;
          });
        }
        setTimeout(() => {
          checkIfEmpty();
        }, 500);
        filterStatus[0].textContent = "Filters cleared, all programs available";
      });

      const options = filter.find(".filter-option");
      if (options.length) {
        options.each((i, optionEl) => {
          const option = $(optionEl);
          const entry = {
            filter: option[0].dataset.filter,
            slug: option[0].dataset.slug,
          };
          const allTitle = filter.find("#filter-name-" + entry.filter)[0]
            .innerHTML;
          const name = filter.find("#filter-name-" + entry.filter)[0];
          const title =
            option[0].innerHTML.trim() === "All"
              ? allTitle
              : option[0].innerHTML.trim();
          // console.log(
          //   "filter entry: ",
          //   "." + option[0].dataset.filter + "-" + option[0].dataset.slug
          // );
          const termFilter = () => {
            if (terms.find((object) => object.filter === entry.filter)) {
              terms.splice(
                terms.findIndex((object) => object.filter === entry.filter),
                1
              );
            }
            terms.push(entry);
            name.innerHTML = title;
            filterPrograms();
          };
          option.on("click", () => {
            termFilter();
          });
          if (
            window.location.hash.substring(1) ===
            option[0].dataset.filter + "-" + option[0].dataset.slug
          ) {
            // console.log("activate filter");
            termFilter();
          }
        });
      }

      const thumbnails = $(".program-thumbnail");
      if (thumbnails.length) {
        thumbnails.each((i, thumbnailEl) => {
          const thumbnail = $(thumbnailEl);

          const button = thumbnail.find(".expand-thumbnail");
          // console.log(button);
          button.on("click", () => {
            thumbnail.toggleClass("open");
            grid.refreshItems().layout();
            if (thumbnail.hasClass("open")) {
              button.attr("aria-expanded", "true");
            } else {
              button.attr("aria-expanded", "false");
            }
          });
        });
      }
    });
  }
});
