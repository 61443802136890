import $ from "jquery";

$(() => {
  const eventBanners = $(".event-banner");
  if (eventBanners.length) {
    eventBanners.each((i, eventBannerEl) => {
      const eventBanner = $(eventBannerEl);
      const closeButton = eventBanner.find(".event-close");

      closeButton.on("click", () => {
        console.log("close");
        eventBanner.removeClass("banner-open");
      });
    });
  }
});
