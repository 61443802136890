import tingle from "tingle.js";
import Glide from "@glidejs/glide";
import Splide from "@splidejs/splide";
import $ from "jquery";

const modals = document.querySelectorAll(".modal-student");

if (modals.length) {
  modals.forEach((element) => {
    const activateGlide = () => {
      const gallery = $(".tingle-modal--visible");
      // console.log("gallery: ", gallery.find(".glide"));
      if (gallery.find(".glide").length) {
        // console.log("glide: ", gallery.find(".glide"));
        const glide = new Glide(gallery.find(".glide")[0], {
          rewind: true,
          perView: 1,
          gap: 16,
          bound: false,
          swipeThreshold: false,
          dragThreshold: false,
        });
        glide.mount();
      }
    };

    const modal = new tingle.modal({
      closeMethods: ["overlay", "button", "escape"],
      closeLabel: "",
      onClose: function () {
        console.log("modal closed");
      },
      onOpen: function () {
        console.log("modal opened");
        activateGlide();
      },
    });

    if (element.querySelector(".modal-content")) {
      modal.setContent(element.querySelector(".modal-content").innerHTML);
    }

    $(modal.modalCloseBtn).prependTo($(modal.modalBox));

    $(element).on("click", (e) => {
      e.preventDefault();
      modal.open();
    });

    // console.log("modal: ", $(modal.modalBox).find(".student-tile"));
    // console.log(
    //   "modal: ",
    //   $(modal.modalBoxContent.children).find(".modal-gallery")
    // );
    // console.log("$(element): ", $(element));

    const imgModals =
      modal.modalBoxContent.children[0].querySelectorAll(".modal-gallery");
    // console.log("element: ", element);
    // console.log("imgModals: ", imgModals);
    // console.log("imgModals length: ", imgModals.length);
    if (imgModals.length) {
      imgModals.forEach((imgModal) => {
        const imageModal = new tingle.modal({
          closeMethods: ["overlay", "button", "escape"],
          closeLabel: "",
          onClose: function () {
            console.log("modal closed");
          },
          onOpen: function () {
            console.log("modal opened");
          },
        });

        if (imgModal.querySelector(".image-modal")) {
          imageModal.setContent(
            imgModal.querySelector(".image-modal").innerHTML
          );
        }

        // console.log("imageModal: ", imageModal);
        // console.log("imgModal: ", imgModal);

        $(imageModal.modalCloseBtn).prependTo($(imageModal.modalBox));

        $(imgModal).on("click", (e) => {
          e.preventDefault();
          // console.log("opening image modal");
          imageModal.open();
        });
      });
    }
  });
}

$(() => {
  const showcases = $(".student-showcase");
  if (showcases.length) {
    showcases.each((i, showcaseEl) => {
      const showcase = $(showcaseEl);

      const splide = new Splide(showcase.find(".splide")[0], {
        rewind: true,
        perPage: 3,
        gap: 20,
        omitEnd: true,
        arrows: false,
        paginationDirection: "ltr",
        type: "slider",
        breakpoints: {
          640: {
            perPage: 1,
          },
          1024: {
            perPage: 2,
          },
        },
      });

      splide.on("pagination:mounted", function (data) {
        // You can add your class to the UL element
        data.list.classList.add("splide__pagination--custom");

        // `items` contains all dot items
        data.items.forEach(function (item) {
          item.button.textContent = String(item.page + 1);
        });

        const getPerView = () => {
          const windowWidth = $(window).outerWidth();
          if (windowWidth < 640) {
            return 1;
          }
          if (windowWidth >= 1024) {
            return 3;
          }
          return 2;
        };

        const prevButton = document.createElement("li");
        prevButton.innerHTML =
          '<button class="rotate-180" aria-label="swipe to previous slide"><svg width="15" height="30" viewBox="0 0 15 30" xmlns="http://www.w3.org/2000/svg"><path d="M0 0V6.00283L9.00071 14.9965L0 23.9972V30L14.9965 14.9965L0 0Z"/></svg></button>';
        prevButton.addEventListener("click", () => {
          splide.go("-" + getPerView());
        });
        data.list.prepend(prevButton);

        const nextButton = document.createElement("li");
        nextButton.innerHTML =
          '<button aria-label="swipe to next slide"><svg width="15" height="30" viewBox="0 0 15 30" xmlns="http://www.w3.org/2000/svg"><path d="M0 0V6.00283L9.00071 14.9965L0 23.9972V30L14.9965 14.9965L0 0Z"/></svg></button>';
        nextButton.addEventListener("click", () => {
          splide.go("+" + getPerView());
        });
        data.list.append(nextButton);
      });

      splide.mount();
    });
  }
});
