// import Glide from "@glidejs/glide";
import $ from "jquery";

$(() => {
  const marquees = $(".logo-marquee");
  if (marquees.length) {
    marquees.each((i, marqueeEl) => {
      const marquee = $(marqueeEl);
      // const glide = new Glide(marquee.find(".glide")[0], {
      //   rewind: true,
      //   perView: 4,
      //   gap: 16,
      //   bound: false,
      //   autoplay: 1000,
      //   animationDuration: 1000,
      //   animationTimingFunc: "linear",
      // });
      // glide.mount();
      const scrollers = marquee.find(".marquee-scroll");
      if (scrollers.length) {
        scrollers.each((i, scrollerEl) => {
          const scroller = $(scrollerEl);
          // console.log("scroller: ", scroller);
          // console.log("scroller width: ", scroller[0].offsetWidth);
          const speed = scroller[0].offsetWidth / 100 + "s";
          // console.log("scroller speed: ", speed);
          scroller[0].style.animation = `scroll ${speed} linear infinite`;
        });
      }
      marquee.on("click", () => {
        console.log("marquee clicked!");
        marquee.toggleClass("paused");
      });
    });
  }
});
