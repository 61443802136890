import Splide from "@splidejs/splide";
import $ from "jquery";

$(() => {
  const carousels = $(".news-events-carousel .splide");
  if (carousels) {
    carousels.each((i, carouselEl) => {
      const sectionTitle = $(carouselEl)
        .parent()
        .parent()
        .parent()
        .find(".section-headline")[0].innerText;

      const previousHeadline = $(carouselEl)
        .parent()
        .prev("div.headline-block");

      let previousTitle = "";

      // console.log("headline: ", previousHeadline);

      if (previousHeadline.length) {
        previousTitle = previousHeadline.find(".section-headline")[0].innerText;
      }

      // console.log("title: ", previousTitle);

      if (previousTitle) {
        carouselEl.ariaLabel = previousTitle;
      } else if (sectionTitle) {
        carouselEl.ariaLabel = sectionTitle;
      }

      const splide = new Splide($(carouselEl)[0], {
        rewind: true,
        perPage: 3,
        gap: 16,
        omitEnd: true,
        arrows: false,
        paginationDirection: "ltr",
        type: "slider",
        breakpoints: {
          800: {
            perPage: 1,
          },
        },
      });

      splide.on("pagination:mounted", function (data) {
        // You can add your class to the UL element
        data.list.classList.add("splide__pagination--custom");

        // `items` contains all dot items
        data.items.forEach(function (item) {
          item.button.textContent = String(item.page + 1);
        });

        const prevButton = document.createElement("li");
        prevButton.innerHTML =
          '<button aria-label="scroll carousel back"><svg width="15" height="30" viewBox="0 0 15 30" xmlns="http://www.w3.org/2000/svg"><path d="M0,15l15,15v-6S6,15,6,15L15,6V0S0,15,0,15Z" stroke-width="0" /></svg></button>';
        prevButton.addEventListener("click", () => {
          splide.go("-1");
        });
        data.list.prepend(prevButton);

        const nextButton = document.createElement("li");
        nextButton.innerHTML =
          '<button aria-label="scroll carousel forward"><svg width="15" height="30" viewBox="0 0 15 30" xmlns="http://www.w3.org/2000/svg"><path d="M0 0V6.00283L9.00071 14.9965L0 23.9972V30L14.9965 14.9965L0 0Z" /></svg></button>';
        nextButton.addEventListener("click", () => {
          splide.go("+1");
        });
        data.list.append(nextButton);
      });

      splide.mount();
    });
  }
});
