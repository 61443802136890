import $ from 'jquery';

$(() => {
  const backToTop = $(".back-to-top");
  if (backToTop.length) {
    backToTop.each((i, toTopEl) => {
      const toTop = $(toTopEl);

      toTop.on('click', function(e) {
        let focusableEls = $('#main-content').find('button, a, input, select, textarea, [tabindex]:not([tabindex="-1"])');
        let firstFocusable = focusableEls.first();
        firstFocusable.trigger('focus');
      })
    });
  }
});