import $ from "jquery";
import tingle from "tingle.js";
import Splide from "@splidejs/splide";

$(() => {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      action_load_posts: "load_faculty",
    },

    load_posts: function (type, filters, pOffset) {
      // clear the contents
      $("#faculty-directory #posts-wrap").empty();

      // Look for the Psychology program (I feel so dirty)
      if (filters.program == 6183 || true) {
        $.get("/?p=" + filters.program, (html) => {
          let newHtml = $($.parseHTML(html)).find("#faculty > div > div");
          // console.log(test);
          // let newHtml = $($.parseHTML(html))
          //   .find("#headline_meet-the-dean")
          //   .parent()
          //   .parent()
          //   .parent();
          //newHtml = newHtml.remove('.headline-block');
          $("#faculty-directory #posts-wrap").html(newHtml.html());
          $("#faculty-directory #posts-wrap .headline-block:first").addClass(
            "hidden"
          );
          $("#faculty-directory #posts-wrap .styled-section").addClass('w-full');
          reInitializeCarousels();

          return true;
        });
        return true;
      }
      Sage.common.loading = true;

      $(".loader").addClass("loading");
      var data = {
        action: Sage.common["action_load_" + type],
        paged: pOffset,
      };
      // console.log('filters: ', filters);
      $.get(yorkville.ajaxurl, { ...data, ...filters }, function (html) {
        $("#faculty-directory #posts-wrap").html(html);
        $(".loader").removeClass("loading");
        Sage.common.loading = false;
        setupFacultyModals();
      }).fail(function (e) {
        console.log(e);
      });
    },
  };

  $(document).on("change", "#faculty-directory [name=program]", function (e) {
    var offset = 0;
    const $this = $(this);
    e.preventDefault();
    Sage.load_posts("posts", { program: $this.val() }, offset);
  });

  Sage.load_posts(
    "posts",
    { program: $("#faculty-directory [name=program]").val() },
    0
  );

  setupFacultyModals();
});

const setupFacultyModals = function () {
  const modals = document.querySelectorAll(".modal-faculty:not(.modal-initialized)");

  if (modals.length) {
    modals.forEach((element) => {
      $(element).addClass('modal-initialized');

      const modal = new tingle.modal({
        closeMethods: ["overlay", "button", "escape"],
        closeLabel: "",
        onClose: function () {
          console.log("modal closed");
        },
        onOpen: function () {
          console.log("modal opened");
        },
      });

      if (element.querySelector(".modal-content")) {
        modal.setContent(element.querySelector(".modal-content").innerHTML);
      }

      $(modal.modalCloseBtn).prependTo($(modal.modalBox));

      $(element).on("click", (e) => {
        e.preventDefault();
        modal.open();
      });
    });
  }
};

const reInitializeCarousels = () => {
  const carousels = $("#posts-wrap .news-events-carousel .splide:not(.splide-initialized)");
  if (carousels) {
    carousels.each((i, carouselEl) => {
      $(carouselEl).addClass('splide-initialized');

      const splide = new Splide($(carouselEl)[0], {
        rewind: true,
        perPage: 3,
        gap: 16,
        omitEnd: true,
        arrows: false,
        paginationDirection: "ltr",
        type: "slider",
        breakpoints: {
          800: {
            perPage: 1,
          },
        },
      });

      splide.on("pagination:mounted", function (data) {
        // You can add your class to the UL element
        data.list.classList.add("splide__pagination--custom");

        // `items` contains all dot items
        data.items.forEach(function (item) {
          item.button.textContent = String(item.page + 1);
        });

        const prevButton = document.createElement("li");
        prevButton.innerHTML =
          '<button aria-label="scroll carousel back"><svg width="15" height="30" viewBox="0 0 15 30" xmlns="http://www.w3.org/2000/svg"><path d="M0,15l15,15v-6S6,15,6,15L15,6V0S0,15,0,15Z" stroke-width="0" /></svg></button>';
        prevButton.addEventListener("click", () => {
          splide.go("-1");
        });
        data.list.prepend(prevButton);

        const nextButton = document.createElement("li");
        nextButton.innerHTML =
          '<button aria-label="scroll carousel forward"><svg width="15" height="30" viewBox="0 0 15 30" xmlns="http://www.w3.org/2000/svg"><path d="M0 0V6.00283L9.00071 14.9965L0 23.9972V30L14.9965 14.9965L0 0Z" /></svg></button>';
        nextButton.addEventListener("click", () => {
          splide.go("+1");
        });
        data.list.append(nextButton);
      });

      splide.mount();

      setupFacultyModals();
    });
  }
};
