import $ from "jquery";

$(() => {
  const archiveFilter = $(".archive-filter");
  if (archiveFilter.length) {
    archiveFilter.each((i, filterEl) => {
      const filter = $(filterEl);

      const checkExpanded = (list) => {
        if ($(list).hasClass("hidden")) {
          filter.find(".j-select-filter").attr("aria-expanded", "false");
        } else {
          filter.find(".j-select-filter").attr("aria-expanded", "true");
        }
      };

      filter.on("click", function (e) {
        let linkList = filter.find(".link-list");
        linkList.toggleClass("hidden");
        linkList.toggleClass("block");
        checkExpanded(linkList);
      });

      $(document).on("click", function (e) {
        let $target = $(e.target);
        if (!$target.closest(filter).length) {
          // console.log("hello");
          let linkList = filter.find(".link-list");
          linkList.addClass("hidden");
          linkList.removeClass("block");
          checkExpanded(linkList);
        }
      });
    });
  }
});
