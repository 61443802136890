import tingle from "tingle.js";
import Glide from "@glidejs/glide";
import $ from "jquery";

const modals = document.querySelectorAll(".modal-logo");

if (modals.length) {
  modals.forEach((element) => {
    const modal = new tingle.modal({
      closeMethods: ["overlay", "button", "escape"],
      closeLabel: "",
      onClose: function () {
        console.log("modal closed");
      },
      onOpen: function () {
        console.log("modal opened");
      },
    });

    if (element.querySelector(".modal-content")) {
      modal.setContent(element.querySelector(".modal-content").innerHTML);
    }

    // console.log("modal: ", modal);
    $(modal.modalCloseBtn).prependTo($(modal.modalBox));

    $(element).on("click", (e) => {
      e.preventDefault();
      modal.open();
    });
  });
}

$(() => {
  const designations = $(".professional-designations");
  //console.log("designations: ", designations);

  if (designations.length) {
    designations.each((i, designationEl) => {
      const designation = $(designationEl);

      // const glide = new Glide(designation.find(".glide")[0], {
      //   rewind: true,
      //   perView: 1,
      //   gap: 16,
      //   bound: false,
      // });
      // glide.mount();

      // const filters = designation.find(".designation-filter");
      const items = designation.find(".filter-item");

      designations.on("change", ".dropdown select", function (e) {
        const $this = $(this);
        const value = $this.val();
        e.preventDefault();
        items.each((i, card) => {
          const $card = $(card);
          const categoryArray = $card.data("terms").split(",");
          if (categoryArray.indexOf(value) != -1 || value == "All") {
            $card.removeClass("hidden");
          } else {
            $card.addClass("hidden");
          }
        });
        // glide.update();
      });
      // glide.on("move.after", () => {
      //   const index = glide.index + 1;
      //   designation.find(".carousel-index").text(index);
      // });

      // if (filters.length) {
      //   filters.each((i, filterEl) => {
      //     const filter = $(filterEl);
      //     const options = filter.find(".filter-option");

      //     if (options.length) {
      //       options.each((i, optionEl) => {
      //         const option = $(optionEl);

      //         option.on("click", () => {
      //           // console.log("name: ", option[0].innerHTML.trim());
      //           // console.log("head: ", filter.find("#filter-name-terms"));
      //           const slug = option[0].dataset["slug"];
      //           filter.find("#filter-name-terms")[0].innerHTML =
      //             option[0].innerHTML.trim();
      //           if (slug === "filter-all") {
      //             items.removeClass("hidden");
      //           } else {
      //             items.addClass("hidden");
      //             items.each((i, itemEl) => {
      //               const item = $(itemEl);
      //               if (item.hasClass(slug)) {
      //                 item.removeClass("hidden");
      //               }
      //             });
      //           }
      //         });
      //       });
      //     }
      //   });
      // }
    });
  }
});
