import $ from "jquery";

$(() => {
  const tabbedCategories = $(".tabbed-categories");
  if (tabbedCategories.length) {
    tabbedCategories.each((i, tabbedCategoryEl) => {
      const tabbedCategory = $(tabbedCategoryEl);

      const tabButtons = tabbedCategory.find(".tab-button");
      const tabContent = tabbedCategory.find(".tab-content");
      // console.log(tabContent);
      if (tabButtons.length) {
        tabButtons.each((i, tabButtonEl) => {
          const tabButton = $(tabButtonEl);

          tabButton.on("click", () => {
            tabButtons.removeClass("active-tab");
            tabButtons.attr("aria-selected", "false");
            tabButton.addClass("active-tab");
            tabButton.attr("aria-selected", "true");
            // console.log("tab: ", tabButton.attr("id"));
            tabContent.removeClass("active-tab");
            // console.log(
            tabbedCategory
              .find(".tabbed-section")
              .find("." + tabButton.attr("id"))
              .addClass("active-tab");
            // );
          });
        });
      }
    });
  }
});
