import $ from "jquery";

const miniSubNav = document.getElementById("mini-subnav");
const isChrome =
  /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);


$(() => {
  const sections = $(".floating-nav-section");
  if (sections.length) {
    sections.each((i, section) => {
      const $section = $(section);
      const $nav = $(section).find(".toc").first();

      window.onscroll = () => {
        stickerToggle($nav, $section);

        // check which is the lowest element in view
      };

      checkElements($section);
    });

    // fix links for CHROME
    if(isChrome) {
      const links = $(".floating-nav-section .toc a");
      links.on('click', function(e) {
        e.preventDefault();
        scrollToTargetAdjusted($(this).attr('href'))
      });
    }
  }
});

function scrollToTargetAdjusted(targetElement){
  if(typeof targetElement !== 'undefined') {
    console.log(targetElement);
    var element = document.querySelector(targetElement);
    var headerOffset = 145;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
         top: offsetPosition,
         behavior: "smooth"
    });
  }
}

function stickerToggle($nav, $section) {
  const offset = $section.offset();
  const y = $(window).scrollTop();
  const height = $section.outerHeight(true);

  const tocBottom = y + 154 + $nav.outerHeight();
  const footerTop = $(".footer").offset().top;
  // console.log("tocBottom: ", tocBottom);
  // console.log("footerTop: ", footerTop);

  if (
    y + 100 >= offset.top &&
    y < offset.top + height - $nav.height() &&
    tocBottom < footerTop
  ) {
    $nav.addClass("toc-stuck");
  } else {
    $nav.removeClass("toc-stuck");
  }
}

function checkElements($section) {
  const elements = $section[0].querySelectorAll("[data-headline]");

  elements.forEach((elem) => {
    inViewport(
      elem,
      (element) => {
        const targetElement = document.getElementById(
          "mini_" + elem.dataset.headline
        );
        if (targetElement) {
          if (element.isIntersecting) {
            targetElement.classList.add("in-viewport");
          } else {
            targetElement.classList.remove("in-viewport");
          }
          fixActive();
        }
      },
      {
        root: document,
      }
    );
  });
}

function inViewport(elem, callback, options = {}) {
  return new IntersectionObserver((entries) => {
    entries.forEach((entry) => callback(entry));
  }, options).observe(elem);
}

function fixActive() {
  const elements = document.querySelectorAll(".in-viewport");

  // only cleanup if there are more highlights than 1
  if (elements.length >= 1) {
    const allNav = miniSubNav.querySelectorAll("[data-mini-nav]");
    allNav.forEach((item) => {
      item.classList.remove("mini-nav-active");
    });
  }
  if (elements.length > 0) {
    elements[elements.length - 1].classList.add("mini-nav-active");
    if (isChrome) {
      elements[elements.length - 1].scrollIntoView();
    } else {
      elements[elements.length - 1].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }
}
