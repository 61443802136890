import $ from "jquery";

$(() => {
  const menu_sections = $(".header .menu").children();
  const menu_info_sections = $(".header .menu-section-info");
  const $searchForm = $(".header .search-form");
  const $searchButton = $(".header .search-button-desktop");
  const $searchClose = $(".header .search-close-desktop");
  const $main = $("main");

  let submenuHeadings = $(menu_sections).find("a[href='#']");
  // console.log(submenuHeadings);
  if (submenuHeadings.length) {
    submenuHeadings.each((i, submenu_heading) => {
      let attrs = {};

      $.each($(submenu_heading).attributes, function (idx, attr) {
        attrs[attr.nodeName] = attr.nodeValue;
      });

      $(submenu_heading).replaceWith(function () {
        return $("<p>", attrs).append($(submenu_heading).contents());
      });
    });
  }

  function openSubmenu(elem) {
    let focusableEls = elem.find("a[href!='#']");
    let firstFocusable = focusableEls.first();
    let lastFocusable = focusableEls.last();
    firstFocusable.trigger("focus");

    lastFocusable.on("keydown", function (e) {
      // console.log(e.key);
      if (e.key === "Tab" && !e.shiftKey) {
        e.preventDefault();
        firstFocusable.trigger("focus");
      }
    });

    firstFocusable.on("keydown", function (e) {
      // console.log(e.key);
      if (e.key === "Tab" && e.shiftKey) {
        e.preventDefault();
        lastFocusable.trigger("focus");
      }
    });
  }

  if (menu_sections.length) {
    menu_sections.each((i, menu_section) => {
      $(menu_section).addClass("menu-section");
      // $(menu_section).removeClass("hidden");
      $(menu_section).attr("role", "presentation");
      // $(menu_section).attr("aria-expanded", false);
      const $sub_menu = $(menu_section).children(".sub-menu");

      // console.log(menu_section);
      // console.log($sub_menu.length);

      if ($sub_menu.length) {
        if ($sub_menu.children("li").children(".sub-menu").length > 0) {
          $sub_menu.addClass("sub-menu-has-children");
          $sub_menu.attr("role", "presentation");
        }

        if (menu_info_sections.length) {
          menu_info_sections.each((i, menu_info_section) => {
            // console.log('class: ', menu_section.textContent.split(/\r?\n/)[0].trim().toLowerCase());
            menu_info_section.classList.remove("hidden");
            if (
              $(menu_info_section).hasClass(
                menu_section.textContent.split(/\r?\n/)[0].trim().toLowerCase()
              )
            ) {
              $(menu_info_section).prependTo($sub_menu);
            }
          });
        }

        $(menu_section).on("click", function (e) {
          e.preventDefault();
          const $target = $("body");
          if ($(menu_section).hasClass("active--section")) {
            $target.removeClass("menu--section--active");
            $(menu_section).removeClass("active--section");
            // $(menu_section).attr("aria-expanded", false);
          } else {
            $(".header .active--section").removeClass("active--section");
            $target.addClass("menu--section--active");
            $(menu_sections).removeClass("active--section");
            // $(menu_sections).attr("aria-expanded", false);
            $(menu_section).addClass("active--section");
            // $(menu_section).attr("aria-expanded", true);
            openSubmenu($sub_menu);
          }
          $target.removeClass("menu--search--active");
        });

        $(menu_section).on("keyup", function (e) {
          if (e.key == "Escape") {
            const $target = $("body");
            $target.removeClass("menu--section--active");
            $(menu_sections).removeClass("active--section");
            // $(menu_sections).attr("aria-expanded", false);
          }
        });

        $sub_menu.on("click", function (e) {
          e.stopPropagation();
        });
      } else {
        $(menu_section).addClass("menu-link");
      }
    });
  }

  function navWrapperFocus() {
    let focusableEls = $(".nav-wrapper").find(
      ".menu-section>a, input.is-search-input"
    );
    focusableEls = focusableEls.add(".header .close-button");
    focusableEls = focusableEls.add(".header #hamburger");
    let firstFocusable = focusableEls.first();
    let lastFocusable = focusableEls.last();
    // console.log("hello from nav wrapper focus");
    // console.log("focusables: ", focusableEls);

    lastFocusable.on("keydown", function (e) {
      // console.log(e.key);
      if (e.key === "Tab" && !e.shiftKey) {
        e.preventDefault();
        firstFocusable.trigger("focus");
      }
    });

    firstFocusable.on("keydown", function (e) {
      // console.log(e.key);
      if (e.key === "Tab" && e.shiftKey) {
        e.preventDefault();
        lastFocusable.trigger("focus");
      }
    });

    focusableEls.on("keyup", function (e) {
      // console.log('escaping nav menu');
      if (e.key == "Escape") {
        const $target = $("body");
        $target.removeClass("menu--active");
        $(menu_sections).removeClass("active--section");
        // $(menu_sections).attr("aria-expanded", false);
      }
    });
  }

  // $('.nav-wrapper').on('keyup', function(e) {
  //   if (e.key == "Escape") {
  //     const $target = $('body');
  //     $target.removeClass('menu--active');
  //     $(menu_sections).removeClass('active--section');
  //     $(menu_sections).attr('aria-expanded', false);
  //   }
  // })

  $(".header #hamburger").on("click", function (e) {
    e.preventDefault();
    const $target = $("body");
    if ($target.hasClass("menu--active")) {
      $target.removeClass("menu--search--active");
    }
    $target.toggleClass("menu--active");
    if ($target.hasClass("menu--active")) {
      navWrapperFocus();
      $(".header #hamburger").attr("aria-expanded", true);
    } else {
      $(".header #hamburger").attr("aria-expanded", false);
    }
  });

  $(".header .close-button").on("click", function (e) {
    e.preventDefault();
    const $target = $("body");
    if ($target.hasClass("menu--search--active")) {
      $target.removeClass("menu--search--active");
    } else {
      $target.removeClass("menu--active");
      $target.removeClass("menu--section--active");
      $(".header .active--section").removeClass("active--section");
      $(".header #hamburger").attr("aria-expanded", false);
    }
  });

  $main.on("click", () => {
    const $target = $("body");
    if ($target.hasClass("menu--active")) {
      $target.removeClass("menu--active");
      $(".header .active--section").removeClass("active--section");
      $(".header #hamburger").attr("aria-expanded", false);
    }
    if ($target.hasClass("menu--section--active")) {
      $target.removeClass("menu--section--active");
      $(".header .active--section").removeClass("active--section");
    }
    if ($target.hasClass("menu--search--active")) {
      $target.removeClass("menu--search--active");
    }
  });

  $(".header .back-button").on("click", function (e) {
    e.preventDefault();
    const $target = $("body");
    if ($target.hasClass("menu--search--active")) {
      $target.removeClass("menu--search--active");
    } else {
      $target.removeClass("menu--section--active");
      $(".header .active--section").removeClass("active--section");
    }
  });

  $(window).resize(function () {
    const $target = $("body");
    $target.removeClass("menu--active");
    $target.removeClass("menu--section--active");
    $target.removeClass("menu--search--active");
    $(".header .active--section").removeClass("active--section");
    $(".is-ajax-search-result").css("display", "");
  });

  $searchForm.on("click", () => {
    const $target = $("body");
    // console.log('search form click');
    $target.addClass("menu--search--active");
  });

  $searchForm.on("keyup", function (e) {
    if (e.key == "Escape") {
      const $target = $("body");
      $target.removeClass("menu--search--active");
    }
  });

  $searchButton.on("click", () => {
    const $target = $("body");
    $target.toggleClass("menu--search--active");
    $target.removeClass("menu--section--active");
    $(menu_sections).removeClass("active--section");
    let focusableEls = $searchForm.find("input");
    let firstFocusable = focusableEls.first();
    // console.log(firstFocusable);
    firstFocusable.trigger("focus");
    // $searchButton.children("svg").toggleClass("hidden");
  });

  $searchClose.on("click", (e) => {
    e.stopPropagation();
    const $target = $("body");
    $target.removeClass("menu--search--active");
  });

  const $onlineLinks = $(".menu-item a");
  if ($onlineLinks.length) {
    $onlineLinks.each((i, linkEl) => {
      const $link = $(linkEl);
      if ($link.attr("href") !== "#") {
        if (
          $link.html() === "Online Programs" ||
          $link.html() === "On Campus Programs"
        ) {
          $link.addClass("external-link-svg");
        }
      }
    });
  }
});
