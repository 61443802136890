import $ from "jquery";

$(() => {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  if($('body').hasClass('archive')) {
    var Sage = {
      // All pages
      common: {
        action_load_posts: "load_posts",
      },

      load_posts: function (type, filters, pOffset) {
        Sage.common.loading = true;
        var itemCountReturned;

        if (pOffset === 0) {
          // only clear current contents if the offset is 0 because otherwise we are loading more
          $(".loader").removeClass("all-posts-loaded");
          //AFL.settings.isotopeGrid.isotope('remove',$(".post-block-wrap"));
          $("#posts-wrap").empty();
        }
        $(".loader").addClass("loading");
        var data = {
          action: Sage.common["action_load_" + type],
          paged: pOffset,
        };
        // console.log('filters: ', filters);
        $.get(yorkville.ajaxurl, { ...data, ...filters }, function (html) {
          $("#posts-wrap").html(html);
          $(".loader").removeClass("loading");
          Sage.common.loading = false;
        }).fail(function (e) {
          console.log(e);
        });
      },
    };

    $('#posts-archive').on("change", ".j-select-filter", function (e) {
      const $this = $(this);
      e.preventDefault();
      let blogCategory = $this.val();
      if(blogCategory == 'news' || blogCategory == 'blog' || blogCategory == 'events' || blogCategory == 'yu-events' ){
        window.history.replaceState( {} , 'Blog', '/category/' + $this.val() + '/');
      } else if(blogCategory == 'All'){
        window.history.replaceState( {} , 'Blog', '/blog/');
      }else{
        window.history.replaceState( {} , 'Blog', '/category/blog/' + $this.val() + '/');
      }

      Sage.load_posts("posts", { category: $this.val() }, 0);
    });

    $('#posts-archive').on("click", ".page-numbers:not(.next,.prev)", function (e) {
      e.preventDefault();
      const offset = e.target.text;
      Sage.load_posts("posts", { category: $('#posts-archive .j-select-filter').val() }, offset);
    });

    $('#posts-archive').on("click", ".prev.page-numbers", function (e) {
      e.preventDefault();
      const offset = parseInt($('#posts-archive .page-numbers.current').text())-1;
      Sage.load_posts("posts", { category: $('#posts-archive .j-select-filter').val() }, offset);
    });

    $('#posts-archive').on("click", ".next.page-numbers", function (e) {
      e.preventDefault();
      const offset = parseInt($('#posts-archive .page-numbers.current').text())+1;
      Sage.load_posts("posts", { category: $('#posts-archive .j-select-filter').val() }, offset);
    });


    Sage.load_posts("posts", { category: $('#posts-archive .j-select-filter').val() }, 0);
  }


});
