import Glide from "@glidejs/glide";
import $ from "jquery";

$(() => {
  const cardCarousels = $(".flipping-cards");
  if (cardCarousels.length) {
    cardCarousels.each((i, carouselEl) => {
      const carousel = $(carouselEl);
      const flipCards = carousel.find(".flipcard");
      const carouselLength = flipCards.length;
      const getPerView = () => {
        const windowWidth = $(window).outerWidth();
        if (windowWidth < 1024) {
          return 1.25;
        }
        if (windowWidth >= 1024) {
          if (carouselLength <= 2) {
            return 2;
          } else {
            return 2.2;
          }
        }
      };
      const getGap = () => {
        const windowWidth = $(window).outerWidth();
        if (windowWidth < 1024) {
          return 10;
        }
        if (windowWidth >= 1024) {
          return 30;
        }
      };
      const checkVisibleCards = (index) => {
        console.log("flipcards: ", flipCards);
        console.log("flipcard: ", $(flipCards[index]));
        flipCards.attr("aria-hidden", "true");
        flipCards
          .slice(index, index + Math.floor(getPerView()))
          .attr("aria-hidden", "false");
      };
      const glide = new Glide(carousel.find(".glide")[0], {
        rewind: true,
        perView: getPerView(),
        gap: getGap(),
        bound: false,
      });
      glide.mount();
      checkVisibleCards(0);
      glide.on("resize", () => {
        // console.log(getGap(), getPerView());
        glide.update({ perView: getPerView(), gap: getGap() });
      });
      glide.on("move.after", () => {
        const index = glide.index + 1;
        carousel.find(".carousel-index").text(index);
        checkVisibleCards(index - 1);
      });
    });
  }

  const cards = $(".flipcard");
  if (cards.length) {
    cards.each((i, cardEl) => {
      const card = $(cardEl);
      const buttons = card.find(".flipbutton");
      buttons.each((i, button) => {
        $(button).on("click", () => {
          if (card[0].style.transform === "rotateY(180deg)") {
            card[0].style.transform = "rotateY(0deg)";
          } else {
            card[0].style.transform = "rotateY(180deg)";
          }
        });
      });

      // const cardBack = card.find(".card-back");
      // const minHeight = card.find(".card-back")[0].offsetHeight + "px";
      // console.log("cardBack: ", cardBack);
      // console.log("height: ", minHeight);
      card.css("min-height", card.find(".card-back")[0].offsetHeight + "px");
      window.onresize = function () {
        card.css("min-height", "auto");
        card.css("min-height", card.find(".card-back")[0].offsetHeight + "px");
      };
    });
  }
});
