import docCookies from "../utils/cookies";

// if (document.querySelector('.site_notification--banner')) {
//     var value = docCookies.getItem("site-banner");

//     if (!value) {
//         document.querySelector('.site_notification--banner').classList.remove('hide');
//         document.querySelectorAll('.has_subnav').forEach(item => {
//             item.classList.add('movedDown')
//         })
//         document.querySelector('.language-nav .langauage-container').classList.add('movedDown')
//     } else {
//         document.querySelector('.site_notification--banner').classList.add('hide');
//         document.querySelectorAll('.has_subnav').forEach(item => {
//             item.classList.remove('movedDown')
//         })
//         document.querySelector('.language-nav .langauage-container').classList.remove('movedDown')
//     }

//     document.querySelectorAll('.site-toggle').forEach(item => {
//         item.addEventListener('click', (e) => {
//             docCookies.setItem("site-banner", true, Infinity, "/");
//             document.querySelector('.site_notification--banner').classList.add('hide');
//             document.querySelectorAll('.has_subnav').forEach(item => {
//                 item.classList.remove('movedDown')
//             })
//             document.querySelector('.language-nav .langauage-container').classList.remove('movedDown')
//         })
//     })
// }

if (document.querySelector('.site_cookie-banner')) {
    var value = docCookies.getItem("cookie-banner");

    // console.log('cookie value: ', value);

    if (!value) {
        document.querySelector('.site_cookie-banner').classList.remove('hide');
    } else {
        document.querySelector('.site_cookie-banner').classList.add('hide');
    }

    document.querySelectorAll('.cookie-toggle').forEach(item => {
        item.addEventListener('click', (e) => {
            docCookies.setItem("cookie-banner", true, Infinity, "/");
            document.querySelector('.site_cookie-banner').classList.add('hide');
        })
    })

    document.querySelectorAll('.cookie-close').forEach(item => {
        item.addEventListener('click', (e) => {
            docCookies.setItem("cookie-banner", false);
            document.querySelector('.site_cookie-banner').classList.add('hide');
        })
    })
}
