import $ from "jquery";

$(() => {
  const loadTabs = () => {
    const tabbedCategories = $(".tuition-fees");
    if (tabbedCategories.length) {
      tabbedCategories.each((i, tabbedCategoryEl) => {
        const tabbedCategory = $(tabbedCategoryEl);

        const tabButtons = tabbedCategory.find(".tab-button");
        const tabContent = tabbedCategory.find(".tab-content");
        if (tabButtons.length) {
          tabButtons.each((i, tabButtonEl) => {
            const tabButton = $(tabButtonEl);

            tabButton.on("click", () => {
              tabButtons.removeClass("active-tab");
              tabButtons.attr("aria-selected", "false");
              tabButton.addClass("active-tab");
              tabButton.attr("aria-selected", "true");
              tabContent.removeClass("active-tab");
              // console.log(
              tabbedCategory
                .find(".tabbed-section")
                .find("." + tabButton.attr("id"))
                .addClass("active-tab");
              // );
            });
          });
        }
      });
    }
  };
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      action_load_posts: "load_tuition",
    },

    load_posts: function (type, filters, pOffset) {
      // console.log("filters: ", filters);
      // console.log("type: ", type);
      Sage.common.loading = true;
      var itemCountReturned;

      if (pOffset === 0) {
        // only clear current contents if the offset is 0 because otherwise we are loading more
        $(".loader").removeClass("all-posts-loaded");
        //AFL.settings.isotopeGrid.isotope('remove',$(".post-block-wrap"));
        $("#program-directory #posts-wrap").empty();
      }
      $(".loader").addClass("loading");
      var data = {
        action: Sage.common["action_load_" + type],
        paged: pOffset,
      };
      // console.log('filters: ', filters);
      $.get(yorkville.ajaxurl, { ...data, ...filters }, function (html) {
        $("#program-directory #posts-wrap").html(html);
        $(".loader").removeClass("loading");
        Sage.common.loading = false;
        // apply animations right away (if any)
        loadTabs();
      }).fail(function (e) {
        console.log("error: ", e);
      });
    },
  };

  $("#program-directory").on("change", "[name=program]", function (e) {
    var offset = 0; //$('#posts-wrap > div').length;
    const $this = $(this);
    e.preventDefault();
    Sage.load_posts("posts", { program: $this.val() }, offset);
  });

  Sage.load_posts(
    "posts",
    { program: $("#program-directory [name=program]").val() },
    0
  );

  loadTabs();
});
