import $ from "jquery";
import tingle from "tingle.js";

$(() => {
  const components = document.querySelectorAll(".meet-the-dean");

  if (components.length) {
    components.forEach((element) => {
      const descriptionModal = element.querySelector(".modal-content");

      if (descriptionModal) {
        console.log("setting up meet the dean modal");
        const modalButton = $(element).find(".open-modal");

        const modal = new tingle.modal({
          closeMethods: ["overlay", "button", "escape"],
          closeLabel: "",
          onClose: function () {
            console.log("modal closed");
          },
          onOpen: function () {
            console.log("modal opened");
          },
        });

        modal.setContent(descriptionModal.innerHTML);

        $(modal.modalCloseBtn).prependTo($(modal.modalBox));

        modalButton.on("click", (e) => {
          e.preventDefault();
          modal.open();
        });
      }

      const component = $(element);

      if (component.find(".truncate-button").length) {
        const readButton = component.find(".truncate-button");

        readButton.on("click", function () {
          component.find(".truncated-text").toggleClass("line-clamp-[18]");
          console.log(readButton);
          if (readButton[0].innerText === "Read Less") {
            readButton[0].innerText = "Read More";
          } else {
            readButton[0].innerText = "Read Less";
          }
        });
      }
    });
  }
});
