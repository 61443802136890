import $ from 'jquery';

$(() => {
  const mores = $(".j-readmore");
  if (mores.length) {
    mores.each((i, moreEl) => {
      const more = $(moreEl);
      more.on('click', function(e) {
        e.preventDefault();
        const $this = $(this);
        const $target = $('#'+$this.data('target'));
        if($target.hasClass('line-clamp-3')) {
            $target.removeClass('line-clamp-3');
            $this.children('.more-text').text('Read less');
            $this.children('.more-arrow').removeClass('rotate-90');
            $this.children('.more-arrow').addClass('-rotate-90');
        } else {
          $target.addClass('line-clamp-3');
          $this.children('.more-text').text(`Read more`);
          $this.children('.more-arrow').removeClass('-rotate-90');
          $this.children('.more-arrow').addClass('rotate-90');
        }
        // $this.addClass('hidden');
      });
    });
  }
});