import $ from "jquery";

$(() => {
  const tabbedAccordions = $(".tabbed-accordion");
  if (tabbedAccordions.length) {
    tabbedAccordions.each((i, tabbedAccordionEl) => {
      const tabbedAccordion = $(tabbedAccordionEl);

      const programSections = tabbedAccordion.find(".program-section");

      tabbedAccordion.on("change", ".dropdown select", function (e) {
        const $this = $(this);
        e.preventDefault();
        programSections.addClass("hidden");
        tabbedAccordion.find("#" + $this.val()).removeClass("hidden");
      });

      if (programSections.length) {
        programSections.each((i, programSectionEl) => {
          const programSection = $(programSectionEl);

          const tabButtons = programSection.find(".tab-button");
          const tabContent = programSection.find(".tab-content");
          // console.log(tabContent);
          if (tabButtons.length) {
            tabButtons.each((i, tabButtonEl) => {
              const tabButton = $(tabButtonEl);

              tabButton.on("click", () => {
                tabButtons.removeClass("active-tab");
                tabButton.addClass("active-tab");
                // console.log("tab: ", tabButton.attr("id"));
                tabContent.removeClass("active-tab");
                // console.log(
                programSection
                  .find(".tabbed-section")
                  .find("." + tabButton.attr("id"))
                  .addClass("active-tab");
                // );
              });
            });
          }

          const sections = programSection.find(".section");
          sections.each((i, sectionEl) => {
            const section = $(sectionEl);
            //console.log("section: ", section);
            section.children(".section-header").on("click", () => {
              section.toggleClass("open");
            });
          });
        });
      }
    });
  }
});
