import Glide from "@glidejs/glide";
import $ from "jquery";

$(() => {
  const cardCarousels = $(".three-cards");
  if (cardCarousels.length) {
    cardCarousels.each((i, carouselEl) => {
      const carousel = $(carouselEl);

      const sectionTitle = $(carouselEl)
        .parent()
        .parent()
        .parent()
        .find(".section-headline")[0].innerText;

      if (sectionTitle) {
        $(carouselEl).children()[0].ariaLabel = sectionTitle;
      }

      // console.log("carousel: ", carousel);
      if (carousel.find(".glide")[0]) {
        const getPerView = () => {
          const windowWidth = $(window).outerWidth();
          if (windowWidth < 768) {
            return 1.25;
          }
          if (windowWidth >= 768 && windowWidth < 1024) {
            return 2.25;
          }
          if (windowWidth >= 1024) {
            return 3;
          }
        };
        const glide = new Glide(carousel.find(".glide")[0], {
          rewind: true,
          perView: getPerView(),
          gap: 20,
          bound: true,
        });
        glide.mount();
        glide.on("resize", () => {
          // console.log(getGap(), getPerView());
          glide.update({ perView: getPerView() });
        });
      }
    });
  }
});
