import $ from "jquery";

$(() => {
  const accordions = $(".accordion");
  if (accordions.length) {
    accordions.each((i, accordionEl) => {
      const accordion = $(accordionEl);
      const nestedSections = $(accordion).find(".nested-section");
      nestedSections.each((i, nestedSectionEl) => {
        const nestedSection = $(nestedSectionEl);
        // console.log("nestedSection: ", nestedSection);
        const subsections = nestedSection.children(".subsection");
        nestedSection.children(".nested-section-header").on("click", () => {
          if (nestedSection.hasClass("open")) {
            subsections.removeClass("open");
          }
          nestedSection.toggleClass("open");
        });
        subsections.each((i, subsectionEl) => {
          const subsection = $(subsectionEl);
          // console.log("subsection: ", subsection);
          subsection.children(".subsection-header").on("click", () => {
            subsection.toggleClass("open");
            if (subsection.hasClass("open")) {
              subsection.children("button").attr("aria-expanded", "true");
            } else {
              subsection.children("button").attr("aria-expanded", "false");
            }
          });
        });
      });

      const sections = $(accordion).find(".section");
      sections.each((i, sectionEl) => {
        const section = $(sectionEl);
        //console.log("section: ", section);
        section.children(".section-header").on("click", () => {
          section.toggleClass("open");
          if (section.hasClass("open")) {
            console.log("expanded");
            section.children("button").attr("aria-expanded", "true");
          } else {
            console.log("closed");
            section.children("button").attr("aria-expanded", "false");
          }
        });
      });
    });
  }
});
