import $ from 'jquery';

$(() => {
  const directories = $(".faculty-directory");
  if (directories.length) {
    directories.each((i, directoryEl) => {
      const directory = $(directoryEl);
      directory.children().children('.show-more').on('click', () => {
        directory.toggleClass('show-all');
        directory.children().children('.show-more').text(function(i, text){
          return text === "Show Less" ? "Show More" : "Show Less";
        });
      })
    });
  }

  const facultyEntries = $(".faculty-entry");
  if (facultyEntries.length) {
    facultyEntries.each((i, entryEl) => {
      const entry = $(entryEl);
      entry.children('.faculty-content').children('.faculty-headline').children('svg').on('click', () => {
        entry.toggleClass('open');
      })
    })
  }

  const facultyFilters = $(".faculty-filter");
  if (facultyFilters.length) {
    facultyFilters.each((i, filterEl) => {
      const filter = $(filterEl);
      filter.children('.filter-open').on('click', () => {
        filter.toggleClass('dropdown_open');
      })
      filter.children('.filter-dropdown').children('.filter-close-button').on('click', () => {
        filter.removeClass('dropdown_open');
      })
      filter.children('.filter-dropdown').children('a').on('click', () => {
        filter.removeClass('dropdown_open');
      })
    })
  }
});