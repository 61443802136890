import $ from "jquery";

$(() => {
  const parallaxWindows = $(".parallax-window");
  if (parallaxWindows.length) {
    const parallaxScroll = (svg, svgCenter) => {
      const scrollCenter = $(window).scrollTop() + $(window).height() / 2;
      // console.log("svg shift: ", scrollCenter - svgCenter);
      const svgShift = (svgCenter - scrollCenter) / 50 + "%";
      svg.css("margin-top", svgShift);
    };

    parallaxWindows.each((i, parallaxWindowEl) => {
      const parallaxWindow = $(parallaxWindowEl);
      const svg = parallaxWindow.children("svg");
      if (svg.length) {
        // console.log("svg: ", svg);
        const svgCenter = svg.offset().top + svg.height() / 2;

        $(window).on("scroll", function () {
          parallaxScroll(svg, svgCenter);
        });

        parallaxScroll(svg, svgCenter);
      }
    });
  }
});
