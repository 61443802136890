import $ from "jquery";

$(() => {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      action_load_events: "load_events",
    },

    load_events: function (type, filters, pOffset) {
      Sage.common.loading = true;
      var itemCountReturned;

      if (pOffset === 0) {
        // only clear current contents if the offset is 0 because otherwise we are loading more
        $(".loader").removeClass("all-posts-loaded");
        //AFL.settings.isotopeGrid.isotope('remove',$(".post-block-wrap"));
        $("#events-wrap").empty();
      }
      $(".loader").addClass("loading");
      var data = {
        action: Sage.common["action_load_" + type],
        paged: pOffset,
      };
      // console.log('filters: ', filters);
      $.get(yorkville.ajaxurl, { ...data, ...filters }, function (html) {
        $("#events-wrap").html(html);
        $(".loader").removeClass("loading");
        Sage.common.loading = false;
      }).fail(function (e) {
        console.log(e);
      });
    },
  };

  $('#events-directory').on("change", ".j-select-filter", function (e) {
    var offset = 0; //$('#posts-wrap > div').length;
    const $this = $(this);
    e.preventDefault();
    Sage.load_events("events", { category: $this.val() }, offset);
  });

  $('#events-directory').on("click", ".page-numbers", function (e) {
    e.preventDefault();
    const offset = e.target.text;
    // console.log("click event: ", `${e.target.href}#category-filter`);
    Sage.load_events("events", { category: $('#events-directory .j-select-filter').val() }, offset);
  });

  Sage.load_events("events", { category: $('#events-directory .j-select-filter').val() }, 0);

});
