import Glide from "@glidejs/glide";
import $ from "jquery";

$(() => {
  const cardCarousels = $(".four-up");
  if (cardCarousels.length) {
    cardCarousels.each((i, carouselEl) => {
      const carousel = $(carouselEl);
      const carouselLength = carousel.find(".tile").length;
      const getPerView = () => {
        const windowWidth = $(window).outerWidth();
        if (windowWidth < 768) {
          return 1.25;
        }
        // if (windowWidth >= 768) {
        //   return 4;
        // }
      };
      const getGap = () => {
        const windowWidth = $(window).outerWidth();
        if (windowWidth < 768) {
          return 10;
        }
        if (windowWidth >= 768) {
          return 20;
        }
      };
      const glide = new Glide(carousel.find(".glide")[0], {
        rewind: true,
        perView: getPerView(),
        gap: getGap(),
        bound: true,
      });
      glide.mount();
      glide.on("resize", () => {
        // console.log(getGap(), getPerView());
        glide.update({ perView: getPerView(), gap: getGap() });
      });
      glide.on("move.after", () => {
        const index = glide.index + 1;
        carousel.find(".carousel-index").text(index);
      });

      const tiles = carousel.find(".tile");
      const slides = carousel.find(".glide__slides");

      const resizeFunction = () => {
        console.log("resizing");
        let windowSize = window.innerWidth;
        if (windowSize >= 767) {
          // console.log("disabling glide");
          // console.log("tiles: ", $(tiles));
          // console.log("glide: ", glide);
          glide.disable();
          $(tiles).removeAttr("style");
          $(slides).removeAttr("style");
        } else {
          // console.log("enabling glide");
          // console.log("glide: ", glide);
          glide.enable();
        }
      };

      const debounce = (func) => {
        let timer;
        return function (event) {
          if (timer) clearTimeout(timer);
          timer = setTimeout(func, 100, event);
        };
      };

      window.addEventListener("resize", debounce(resizeFunction));
      resizeFunction();
    });
  }
});
